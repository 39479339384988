<template>
  <div class="form-container" :class="{ disabled }">
    <i class="icon" :class="icon" v-if="icon"></i>
      <select :id="itemId" v-model="result" :multiple="multiple">
          <option v-if="default_option" :value="default_option.value" selected="selected">{{default_option.name}}</option>
          <option :value="opt.value" v-for="opt in options" :key="opt.value">
            {{ opt.name }}
          </option>
    </select>
    <label :for="itemId">{{ label }}</label>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [Number, Array, String],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    default_option: {
      type: Object,
      default: null
    },
  },
  data: () => ({
    itemId: null,
  }),
  computed: {
    result: {
      get() {
        if (Array.isArray(this.modelValue)) {
            //return this.modelValue.slice(-1)[0]?.value;

            const selected = this.modelValue.slice(-1)[0];
            if(selected) return selected.value;

            return this.default_option.value;
        }else{
          return this.modelValue;
        }
      },
      set(value) {
        if (Array.isArray(this.modelValue)){
          if(this.modelValue.find(m => m.value === value)) return;
          this.$emit("update:modelValue", value !== "" ? [...this.modelValue, this.options.find(m => m.value === value)] : null)
        }else{
          this.$emit("update:modelValue", value !== "" ? value : null);
        }
      },
    },
  },
  mounted() {
    this.fillId();
  },
  methods: {
    fillId() {
      this.itemId = Math.floor(Math.random() * Date.now());
    },
    emitChange(event) {
      this.$emit('selectChange', event.target.value);
    }
  },
};
</script>

<style></style>
