<template>
  <div class="pill">
    <p>{{content}}</p>
    <i @click="$emit('onIconClick')" class="icon" :class="icon" v-if="icon && (type!=='text')"></i>
  </div>
</template>

<script>
export default {
  props:{
    content: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: null,
    },
    type:{
      type: String,
      default: "pill"
    }
  },
}
</script>

<style lang="scss" scoped>
.pill {
  padding: 2px;
  display: flex;
  width: max-content;
  height: 30px;
  border-radius: 0.25rem;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  & .icon{
    margin: 0px 0.5rem;
    position: relative;
    top: 0.20rem;
    color:rgba(139, 139, 139, 0.733);
    cursor:pointer;
    &:hover{
      color:rgba(139, 139, 139, 1);
    }
  }
}
</style>