<template>
  <vue-modal @close="$emit('close')" ref="modal" size="sm">
    <template #title>Contenido</template>
    <template #body>
<!-- forzando push !-->
      <section class="main_section">
        <div class="left col-md-6">
          <FormText
              label="Título"
              icon="fa-solid fa-heading"
              v-model="form.titulo"
              :disabled="(item && item.isShowing)"
          />
          <FormText
              label="Extracto"
              icon="fa-solid fa-paragraph"
              :textarea="true"
              v-model="form.extracto"
              :disabled="(item && item.isShowing)"
          />
          <FormHtml
              label="Texto"
              icon="fa-solid fa-align-left"
              v-model="form.texto"
              :disabled="(item && item.isShowing)"
          />
          <FormText
              label="URL"
              icon="fa-solid fa-link"
              v-model="form.url"
              :disabled="(item && item.isShowing)"
          />
          <div class="row url-externa-espacio">
            <div class="col-md-3">
              <FormSwitch
                  v-model="newFormatoURL"
                  label="URL EXTERNA"
                  icon="fa-solid fa-star"
              />
            </div>
            <template v-if="newFormatoURL === true">
              <div class="col-md-8">
                <FormText
                    label="URL EXTERNA"
                    icon="fa-solid fa-star"
                    v-model="newURLEXTERNA"
                />
              </div>
            </template>
          </div>
          <template v-if="CheckRoles.SysAdmin === this.$store.getters.user.role_id">
            <FormText
                label="Agregar nuevo campo (Presione ENTER para añadir)"
                icon="fa-solid fa-star"
                v-model="form.bonus"
                @keypress.enter="onNewField"
                :disabled="(item && item.isShowing)"
            />
            <div
                v-for="(field, index) in form.campos_bonus"
                :key="index"
                class="bonus-field"
            >
              <button v-if="(item && item.isShowing === false) || (!item)" type="button" class="remove-field close" @click="removeBonus(field)">
                <span aria-hidden="true">&times;</span>
              </button>
              <FormText
                  :label="field.label"
                  icon="fa-solid fa-star"
                  v-model="field.value"
                  :disabled="(item && item.isShowing)"
              />
            </div>
          </template>
        </div>
        <div class="right col-md-6">
          <FormSwitch
              label="Publicar"
              icon="fa-solid fa-paragraph"
              v-model="form.publicar"
              :disabled="(item && item.isShowing)"
          />
          <div class="d-flex gap-3">
            <FormDate :disabled="(item && item.isShowing)" v-model="form.desde" label="Desde"/>
            <FormDate :disabled="(item && item.isShowing)" v-model="form.hasta" label="Hasta"/>
          </div>
          <div class="d-flex gap-3">
            <!-- FormText
              label="Tipo"
              icon="fa-solid fa-mask"
              v-model="form.tipo"
              :disabled="(item && item.isShowing)"
            / -->
            <FormSelect
                v-model="form.tipo"
                label="Tipo Contenido"
                :options="tipos"
                icon="fa-solid fa-mask"
            />
            <FormSelect
                v-model="newFormato"
                label="Formato"
                :options="formatos"
                icon="fa-solid fa-star"
            />
            <button v-if="(item && item.isShowing === false) || (!item)" type="button" class="remove-field-formato close" @click="removeBonusFORMATO(newFormato)">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="d-flex gap-3">
            <FormSelect
                v-model="form.selCat"
                label="Categoría"
                :options="categorias"
                icon="fa-solid fa-columns"
                :disabled="(item && item.isShowing)"
            />
            <FormText
                label="Posición"
                icon="fa-solid fa-arrow-down-1-9"
                v-model="form.pos"
                :disabled="(item && item.isShowing)"
            />
          </div>
          <div class="d-flex gap-3">
            <FormSelect
                v-model="form.selGal"
                label="Galerías"
                :options="galerias"
                icon="fa-solid fa-images"
                :disabled="(item && item.isShowing)"
                :default_option="{name: 'Seleccione una galeria', value:'null'}"
            />
            <div>
              <div v-if="(item && item.isShowing === false) || (!item)" class="button_container">
                <button  class="btn btn-secondary-shade" v-for="(gal, index) in form.selGal" :key="index" @click="handleRemoveGaleria(gal)">
                  {{gal.name}}
                </button>
              </div>
              <div v-else class="button_container">
                <button  class="btn  btn-disabled" v-for="(gal, index) in form.selGal" :key="index">
                  {{gal.name}}
                </button>
              </div>
            </div>
          </div>
          <div class="imagen-destacada">
            <FormUploader v-if="(item && item.isShowing === false) || (!item)" imageType @onChange="onImgUploaded">
              <button class="btn btn-primary">Subir Imagen Destacada</button>
            </FormUploader>
            <span v-else-if="form.img?.url" class="btn">Imagen Destacada</span>
            <div
                class="img-container img-container-md contain d-inline-block"
                :style="`background-image: url('${form.img.url}')`"
                v-if="form.img?.url"
            >
              <div v-if="(item && item.isShowing === false) || (!item)" class="img-buttons">
                <button class="btn btn-outline-danger btn-rounded" @click="deleteImg">
                  <i class="fa-solid fa-trash"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="imagen-destacada">
            <FormUploader v-if="(item && item.isShowing === false) || (!item)" imageType @onChange="onImgMobileUploaded">
              <button class="btn btn-primary">Subir Imagen Destacada Celular</button>
            </FormUploader>
            <span v-else-if="form.imgmobile?.url" class="btn">Imagen Destacada Celular</span>
            <div
                class="img-container img-container-md contain d-inline-block"
                :style="`background-image: url('${form.imgmobile.url}')`"
                v-if="form.imgmobile?.url"
            >
              <div v-if="(item && item.isShowing === false) || (!item)" class="img-buttons">
                <button class="btn btn-outline-danger btn-rounded" @click="deleteImgMobile">
                  <i class="fa-solid fa-trash"></i>
                </button>
              </div>
            </div>
          </div>
          <hr>
          <FormText
              label="Etiquetas (Presione ENTER para añadir)"
              icon="fa-solid fa-tags"
              v-model="form.label"
              @keypress.enter="onNewTag"
              :disabled="(item && item.isShowing)"
          />
          <div class="pills-container">
            <Pill
                v-for="(etiqueta, index) in form.etiquetas"
                :key="index"
                :content="etiqueta"
                icon="fa-solid fa-xmark"
                :type="((item && item.isShowing === false) || (!item)) ? 'pill' : 'text'"
                @onIconClick="handleRemove(etiqueta)"
            />
          </div>
          <template v-if="newFormato === 'acordeon'">
            <div>
              <FormText
                  label="Titulo del Acordeon (Presione ENTER para añadir)"
                  icon="fa-solid fa-star"
                  v-model="form.bonus"
                  @keypress.enter="onNewField"
                  :disabled="(item && item.isShowing)"
              />
              <div class="largo-acordeon-text needScroll overflow-auto d-flex flex-column-reverse">
                <div
                    v-for="(field, index) in form.campos_bonus"
                    :key="index"
                    class="bonus-field"
                >
                  <template v-if="
                form.campos_bonus[index].label !== 'FORMATO'
              && form.campos_bonus[index].label !== 'ICON'
              && form.campos_bonus[index].label !== 'URL EXTERNA'
              && form.campos_bonus[index].label !== 'MENU-LABEL'
              && form.campos_bonus[index].label !== 'DATA-FUNTE'
              "
                  >
                    <button v-if="(item && item.isShowing === false) || (!item)" type="button" class="remove-field-especial close" @click="removeBonus(field)">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <FormHtml
                        :id="`ScrollAcordeon${index}`"
                        :label="field.label"
                        icon="fa-solid fa-star"
                        v-model="field.value"
                        :disabled="(item && item.isShowing)"
                    />
                  </template>
                </div>
              </div>
            </div>
          </template>
        </div>
      </section>
    </template>
    <template #footer>
      <button v-if="(item && item.isShowing === false) || (!item)" class="btn btn-primary" @click="saveData">Guardar</button>
    </template>
  </vue-modal>
</template>
<script>
import {CheckRoles} from '@/acl/constants.js'
import FormText from "@/components/Form/FormText.vue";
import FormHtml from "@/components/Form/FormHtml.vue";
import FormSwitch from "@/components/Form/FormSwitch.vue";
import FormDate from "@/components/Form/FormDate.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormUploader from "@/components/Form/FormUploader.vue";
import Pill from "@/components/Pill.vue";

export default {
  components: {
    FormText,
    FormHtml,
    FormSwitch,
    FormDate,
    FormSelect,
    FormUploader,
    Pill,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      titulo:null,
      extracto:null,
      texto:null,
      publicar: false,
      desde:null,
      hasta:null,
      url:null,
      tipo:null,
      pos:null,
      etiquetas:[],
      selGal: [],
      selCat:null,
      campos_bonus:[],
    },
    newFormato: null,
    newFormatoURL: null,
    newURLEXTERNA: null,
  }),
  watch:{
    newFormato: function(val){
      if (val !== null){const LABEL = "FORMATO";
        const INDICE = this.form.campos_bonus.findIndex((data)=> data.label === LABEL)
        if(INDICE === -1){
          this.form.campos_bonus.push({label: LABEL, value: val})
        }else{
          this.form.campos_bonus[INDICE].value= val
        }}
    },
    newFormatoURL: function(val){
      if (val === true){
        const LABEL = "URL EXTERNA"
        const INDICE = this.form.campos_bonus.findIndex((data)=> data.label === LABEL)
        if(INDICE === -1){
          this.form.campos_bonus.push({label: LABEL, value: ''})
        }
      }else{
        this.newURLEXTERNA = null
        const LABEL = "URL EXTERNA"
        const INDICE = this.form.campos_bonus.findIndex((data)=> data.label === LABEL)
        if(INDICE !== -1){
          this.removeBonus({label: LABEL})
        }
      }
    },
    newURLEXTERNA: function(val){
      if(val !== null){
        const LABEL = "URL EXTERNA";
        const INDICE = this.form.campos_bonus.findIndex((data)=> data.label === LABEL)
        if(INDICE === -1){
          this.form.campos_bonus.push({label: LABEL, value: val})
        }else{
          this.form.campos_bonus[INDICE].value= val
        }
      }
    }
  },
  computed:{
    CheckRoles(){
      return CheckRoles
    },
    formatos(){
      return [{value:'tabla', name: 'Tabla'},{value:'pdf', name: 'PDF'},{value:'acordeon', name: 'Acordeon'}, {value:'blog', name: 'Blog'}, {value:'torneo', name: 'Torneo'}]
    },
    tipos(){
      return [
        {value:'FEATURES', name: 'FEATURES'},
        {value:'ICONOS-CONTACTO', name: 'ICONOS-CONTACTO'},
        {value:'PREGUNTAS-FRECUENTES', name: 'PREGUNTAS-FRECUENTES'},
        {value:'GALERIA-HOME', name: 'GALERIA-HOME'},
        {value:'CARD-COMITE', name: 'CARD-COMITE'},
        {value:'ESTADISTICAS', name: 'ESTADISTICAS'},
        {value:'EXPOSICIONES', name: 'EXPOSICIONES'},
        {value:'EXAMENES', name: 'EXAMENES'},
        {value:'SIEGERSCHAU', name: 'SIEGERSCHAU'},
        {value:'SERVICIOS', name: 'SERVICIOS'},
        {value:'CARRUSEL-HOME', name: 'CARRUSEL-HOME'},
        {value:'TOPBAR', name: 'TOPBAR'},
        {value:'BLOG', name: 'BLOG'},
        {value:'INSTITUCION', name: 'INSTITUCION'},
        {value:'RESULTADOS', name: 'RESULTADOS'},
        {value:'ULTIMAS-NOTICIAS', name: 'ULTIMAS-NOTICIAS'},
        {value:'INFORMACION-UTIL', name: 'INFORMACION-UTIL'},
        {value:'REGLAMENTOS', name: 'REGLAMENTOS'},
        {value:'REGLAMENTO-TORNEO', name: 'REGLAMENTO-TORNEO'},
        {value:'REGLAMENTO-ADIESTRAMIENTO', name: 'REGLAMENTO-ADIESTRAMIENTO'}

      ]
    },
    categorias(){
      // return this.$store.getters.listaCategorias.map(c => ({value: c.id, name: c.nombre}))
      return this.$store.getters.listaCategorias.map(c => {
        const cat = {value: c.id, name: c.nombre}
        if (c.pos === 0) cat.name = `=== ${c.nombre} ===`
        if (c.pos === 1) cat.name = `### ${c.nombre} ###`
        if (c.pos > 1){
          const spaces = '-'.repeat((c.pos-1) * 1)
          cat.name = `${spaces}> ${c.nombre}`
        }
        return cat;
      })
    },
    galerias(){
      return this.$store.getters.listaGalerias.map(c => ({value: c.id, name: c.nombre}))
    }
  },
  mounted() {
    if (this.item) {
      this.newURLEXTERNA = this.item?.contenido_bonus?.find(a => a.label === 'URL EXTERNA')?.value || null;
      this.form = this.item;
      if (this.item.publicar === null) this.form.publicar = false;
      this.form.tipo = this.item.tipo_contenido.nombre;
      this.form.selCat = this.item.id_categoria;
      this.form.selGal = this.item.galerias.map(g => ({value: g.id, name: g.nombre}));
      this.form.etiquetas = this.item.contenido_etiqueta.map(e => e.nombre_etiqueta) || [];
      this.form.campos_bonus = this.item.contenido_bonus.map(c => ({label: c.label, value: c.value})) ||[];
      this.form.label = '';
      this.form.img = {
        url: this.item.img_url,
      };
      this.form.imgmobile = {
        url: this.item.imgmobile_url,
      };
      this.newFormato = this.item.contenido_bonus.find(a => a.label === 'FORMATO' )?.value || null;
    }
    if(this.newURLEXTERNA !== null) return this.newFormatoURL = true;
    this.$store.dispatch('getListaGalerias')
    this.$store.dispatch('getArbolCategorias')
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    handleRemoveGaleria(gal){
      this.form.selGal = this.form.selGal.filter(g => g.value != gal.value)
    },
    saveData() {
      if(!this.form.titulo) this.$toast.error("Es necesario un Titulo");
      if(!this.form.tipo) this.$toast.error("Es necesario un Tipo");
      if(!this.form.pos) this.$toast.error("Es necesaria una Posición");

      if(this.form.titulo && this.form.tipo && this.form.pos){
        const form = new FormData();
        let regexDeleteCaracterEspecial = /[^a-zA-Z_0-9 ]/g;
        this.form.url =`${process.env.VUE_APP_FRONTEND_URL}${this.form.tipo?.toLowerCase()}/${this.form.titulo?.replace(regexDeleteCaracterEspecial, "").toLowerCase().replaceAll(' ', "-")}`
        form.append('titulo', this.form.titulo);
        if (this.form.extracto) form.append('extracto', this.form.extracto);
        if (this.form.texto) form.append('texto', this.form.texto);
        form.append('publicar', this.form.publicar);
        if (this.form.desde) form.append('desde', this.form.desde);
        if (this.form.hasta) form.append('hasta', this.form.hasta);
        if (this.form.url) form.append('url', this.form.url);
        form.append('tipo', this.form.tipo);
        form.append('pos', this.form.pos);
        if (this.form.selCat) form.append('id_categoria', this.form.selCat);
        this.form.selGal.forEach(g => form.append('galerias', g.value));
        this.form.etiquetas.forEach(e => form.append('etiquetas', e));
        form.append('campos_bonus', JSON.stringify(this.form.campos_bonus))
        if (this.form.img?.file) form.append("img", this.form.img.file);
        if (this.form.delete_img) form.append("delete_img", true);

        if (this.form.imgmobile?.file) form.append("imgmobile", this.form.imgmobile.file);
        if (this.form.delete_imgmobile) form.append("delete_imgmobile", true);

        if (this.form.id) {
            this.$store
              .dispatch("updateContenido", {
                id: this.form.id,
                form,
              })
              .then(() => {
                this.close();
              });

        } else {
          this.$store.dispatch("createContenido", form).then(() => {
            this.close();
          });
        }
      }
      this.$emit('saved')
    },
    onNewTag(){
      if (!this.form.label.length) {
        this.$toast.error("No se puede agregar una etiqueta vacía");
        return}
      if (this.form.etiquetas.includes(this.form.label.toUpperCase())) {
        this.$toast.error("Ya existe la etiqueta");
        return;
      }
      this.form.etiquetas = [...this.form.etiquetas, this.form.label.toUpperCase()];
      this.form.label = '';
    },
    onNewField(){
      if (!this.form.bonus.length) {
        this.$toast.error("No se puede agregar un campo vacío");
        return}
      if (this.form.campos_bonus.find(c => c.label == this.form.bonus.toUpperCase())) {
        this.$toast.error("Ya existe el campo");
        return;
      }
      this.form.campos_bonus = [...this.form.campos_bonus, {label: this.form.bonus.toUpperCase(), value: ''}];
      this.form.bonus = '';
      let FormatAcordeonCreated = this.form.campos_bonus.find(e=> e.value.toUpperCase() === 'ACORDEON')
      if(FormatAcordeonCreated){
        let index = this.form.campos_bonus.length - 1
        setTimeout(function(){
          document.getElementById(`ScrollAcordeon${index}`).scrollIntoView({ behavior: 'smooth', block: 'end' });
        },1)
      }
    },
    removeBonus(field){
      this.form.campos_bonus = this.form.campos_bonus.filter(c => c.label != field.label)
    },
    removeBonusFORMATO(value){
      if (value === 'acordeon'){
        this.newFormato = null,
            [{lable: 'FORMATO'},{lable: 'ICON'},{lable: 'URL EXTERNA'},{lable: 'MENU-LABEL'},{lable: 'DATA-FUNTE'}]
        this.removeBonus({label: 'FORMATO'})
      }else{
        this.newFormato = null,
            this.removeBonus({label: 'FORMATO'})
      }
    },
    handleRemove(etiqueta){
      this.form.etiquetas = this.form.etiquetas.filter(e => e != etiqueta)
    },
    onImgUploaded({ file, url }) {
      this.form.img = {
        file,
        url,
      };
    },
    onImgMobileUploaded({ file, url }) {
      this.form.imgmobile = {
        file,
        url,
      };
    },
    deleteImg() {
      this.form.img = null;
      this.form.delete_img = true;
    },
    deleteImgMobile() {
      this.form.imgmobile = null;
      this.form.delete_imgmobile = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.button_container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 28px;
  gap: 5px;
  & .btn-disabled{
    color: #999293;
  }
}
.url-externa-espacio{
  justify-content: space-between;
}

.main_section{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  // gap: 5px;
}

.pills-container{
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}
.imagen-destacada{
  display: flex;
  align-items: center;
}
.bonus-field{
  position:relative;
}
.remove-field{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.remove-field-formato{
  position: absolute;
  top: 142px;
  left: 400px;
  z-index: 1;
}
.remove-field-especial{
  position: absolute;
  top: -4px;
  left: -14px;
  z-index: 1;
}
.largo-acordeon-text{
  height: 55vh;
}
</style>

