<template>
  <div class="form-container">
    <label v-if="label">{{ label }}</label>
    <div class="formHtml" :class="{ 'mt-1': label !== null }">
      <trumbowyg v-model="resultHtml" :config="config" :disabled="disabled" />
    </div>
  </div>
</template>

<script>
import Trumbowyg from "vue-trumbowyg";
import "trumbowyg/dist/ui/trumbowyg.css";

// Plugins are optional
import 'trumbowyg/dist/plugins/table/trumbowyg.table.js'
import 'trumbowyg/dist/plugins/table/ui/trumbowyg.table.css'
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js'
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.min.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/fontsize/trumbowyg.fontsize'

export default {
  components: {
    Trumbowyg,
  },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    config: {
      type: Object,
      default: () => ({
        advanced: {
          btnsAdd: ['tableCellBackgroundColor' ,'tableBorderColor'],
        },
        btns: [
          ["viewHTML"],
          ["undo", "redo"], // Only supported in Blink browsers
          ["formatting"],
            ['fontsize'],
          ['foreColor', 'backColor'],
          ["strong", "em", "del"],
          ["link"],
          ["insertImage"],
          ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"],
          ["unorderedList", "orderedList"],
          ["horizontalRule"],
          ["removeformat"],
          ['table' ,'tableCellBackgroundColor' ,'tableBorderColor' ],
          ['fullscreen']
        ],
        semantic: {
          div: "div", // Editor does nothing on div tags now
        },
        resetCss: true,
        removeformatPasted: true,
        autogrow: true,
        linkTargets: ['_blank', '_self'],
        tagClasses: {
          h1: 'h1', // Bootstrap example
          blockquote: 'bg-grey-100 rounded-xl', // Tailwind CSS example
          table:'myTabla'
        },
        plugins:{
          table:
              {
                rows: 4,
                columns:4,
                allowHorizontalResize: true,
                allowCustomBackgroundColor:true,
                backgroundColorList:[
                  'ffffff', '000000', 'eeece1', '1f497d', '4f81bd', 'c0504d', '9bbb59', '8064a2', '4bacc6', 'f79646', 'ffff00',
                  'f2f2f2', '7f7f7f', 'ddd9c3', 'c6d9f0', 'dbe5f1', 'f2dcdb', 'ebf1dd', 'e5e0ec', 'dbeef3', 'fdeada', 'fff2ca',
                  'd8d8d8', '595959', 'c4bd97', '8db3e2', 'b8cce4', 'e5b9b7', 'd7e3bc', 'ccc1d9', 'b7dde8', 'fbd5b5', 'ffe694',
                  'bfbfbf', '3f3f3f', '938953', '548dd4', '95b3d7', 'd99694', 'c3d69b', 'b2a2c7', 'b7dde8', 'fac08f', 'f2c314',
                  'a5a5a5', '262626', '494429', '17365d', '366092', '953734', '76923c', '5f497a', '92cddc', 'e36c09', 'c09100',
                  '7f7f7f', '0c0c0c', '1d1b10', '0f243e', '244061', '632423', '4f6128', '3f3151', '31859b', '974806', '7f6000'
                ],
                displayBackgroundColorsAsList:true,
                borderColorList:[
                  'ffffff', '000000', 'eeece1', '1f497d', '4f81bd', 'c0504d', '9bbb59', '8064a2', '4bacc6', 'f79646', 'ffff00',
                  'f2f2f2', '7f7f7f', 'ddd9c3', 'c6d9f0', 'dbe5f1', 'f2dcdb', 'ebf1dd', 'e5e0ec', 'dbeef3', 'fdeada', 'fff2ca',
                  'd8d8d8', '595959', 'c4bd97', '8db3e2', 'b8cce4', 'e5b9b7', 'd7e3bc', 'ccc1d9', 'b7dde8', 'fbd5b5', 'ffe694',
                  'bfbfbf', '3f3f3f', '938953', '548dd4', '95b3d7', 'd99694', 'c3d69b', 'b2a2c7', 'b7dde8', 'fac08f', 'f2c314',
                  'a5a5a5', '262626', '494429', '17365d', '366092', '953734', '76923c', '5f497a', '92cddc', 'e36c09', 'c09100',
                  '7f7f7f', '0c0c0c', '1d1b10', '0f243e', '244061', '632423', '4f6128', '3f3151', '31859b', '974806', '7f6000'
                ],
                displayBorderColorsAsList:true,
                styler:'myTabla',
                dropdown: [
                  {
                    title: 'tableOthers',
                    buttons: [
                      // Cell merge/split
                      'tableCellBackgroundColor',
                      'tableBorderColor',
                      'tableDestroy',
                    ]
                  }
                ],
              }
        },
      }),
    },
  },
  data: () => ({
    resultHtml: null,
  }),
  watch: {
    resultHtml(val) {
      this.$emit("update:modelValue", val);
    },
  },
  mounted() {
    this.resultHtml = this.modelValue;
  },
};
</script>

<style>


</style>
